import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { PandaProvider, usePandaWallet } from "panda-wallet-provider";
import $ from 'jquery';
import init, { P2PKHAddress, ExtendedPrivateKey, Script, SigHash, Transaction, TxIn, TxOut, BSM, PublicKey, ExtendedPublicKey } from 'bsv-wasm-web';
import Button from 'react-bootstrap/Button';
import crypto from "crypto-browserify"
//import { MnemonicEN } from '@bsvwasm/mnemonic'
import { Bip32, Bip39, Bsm, KeyPair, Address } from 'bsv';
import Leaderboard from "./leaderboard.jsx"
import HCButton from "./HCButton.jsx"
import cookieParser from 'cookie-parser';
import { SocketProvider } from './SocketContext.js';
import Chat from './Chat.js';

function Snapshot(){
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const instances = [];

  const getSnapshot = () => {
    setLoading(true); // Set loading state to true

    $.ajax({
      type: "POST",
      url: "/snapshot",
      data: {token: getCookie('token'), hc_token: getCookie('hc_token')},
      success: function(data) {
        setMessage("You have claimed your Gopnikz. "+data.minted+" total Gopnikz are now squatting on HandCash. Please check your HandCash wallet notifications.");
        console.log(data);
        setLoading(false); // Set loading state to false after success
      },
      error: function(data) {
        console.log(data);
        if (data.status === 400) {
          setMessage("You have already transferred your Gopnikz. Please check your HandCash wallet notifications.");
        } else {
          setMessage(`Error: `+data.status);
        }
        setLoading(false); // Set loading state to false after error
        
      }
    });
  }
 
  return (
    <div>
      <button id="claim" class="btn btn-light" onClick={getSnapshot}><h4><b>Claim Gopnikz</b></h4></button><br></br>
      {loading && <div class="lds-ring"><div></div><div></div><div></div><div></div></div>} {/* Add loading circle */}
      <br></br>
      {message && <p>{message}</p>}
    </div>
  );
}


function ConnectPanda() {
  const initProvider = () => {
    if ('panda' in window) {
      const provider = window.panda;

      if (provider?.isReady) {
        return provider;
      }
    }

    window.open('https://chromewebstore.google.com/detail/panda-wallet/mlbnicldlpdimbjdcncnklfempedeipj', '_blank');
  };

  //const wallet = usePandaWallet();
  



  const useHandleConnect = async () => {
    const wallet = initProvider(); // see "Detecting the Provider"c
    const isReady = wallet?.isReady;
    if (!isReady) {
      window.open(
        "https://chromewebstore.google.com/detail/panda-wallet/mlbnicldlpdimbjdcncnklfempedeipj",
        "_blank"
      );
      return;
    }

    const identityPubKey = await wallet.connect();
    
    if (identityPubKey) {
      const wallet = initProvider();
      console.log(identityPubKey);
      try {
        
        await init();
        console.log(wallet)
        //const { ordAddress, retrieveKeys, verifyPassword, ordPubKey, bsvAddress } = 1
        
        const { bsvAddress, ordAddress, identityAddress } = await wallet.getAddresses();
        console.log(bsvAddress);
        console.log(ordAddress);
        console.log(identityAddress);

        //


        const response = await wallet.signMessage( { message: "Panda Wallet Is Awesome!123", tag: { label: 'panda', id: 'ord', domain: '', meta: {} }});
        console.log(response);
        const pubKey = response.pubKey;
        const signature = response.sig;
        const message = response.message;

        try {
          const data = {
            paymail: "panda",
            origin: "panda",
            pubkey: pubKey,
            signature: signature,
            payload: message,
            address:ordAddress
          };
          fetch("/auth", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add any other headers if needed
            },
            body: JSON.stringify(data)
          }).then((data)=>{console.log(data); window.location.reload()})
            .catch(error => {
              console.error('Error:', error);
            });
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.log(err);
      }
    }else{
      console.log("error123")
    }
  };

  return (
    <button class="btn btn-success" id="ywbutton" onClick={useHandleConnect}>Connect Yours.org</button>
  );
}

function RunInvCount(){
  const [runInv, setRunInv] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/runinv");
      const data = await response.json(); // assuming the response is in JSON format
      //console.log(data.items)
      setRunInv(data.items);
    };

    fetchData();
  }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

  // rest of your component
  return (
    <div>
      <h4>RelayX Items</h4>
      <div className="inventory-grid">
        {runInv.length > 0 ? ( // Check if inv has items before rendering the grid
          runInv.map((item) => (
            <div key={item.id} className="inventory-item">
              
              <div>{item.name}</div>
              <img src={item.imageUrl} alt={item.name} />
            </div>
          ))
        ) : (
          <p>Loading inventory...</p>
        )}
      </div>
    </div>
  );
}

async function orderTickets(){
  const response = await fetch("/order_tickets")
  console.log(response)
  if(response.ok){
    window.location.href = (await response.json()).URL
  }else{
    alert("Error with payment request: " + response.status)
  }
}

function HCInvCount() {
  const [inv, setInv] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/hcinv");
      const data = await response.json(); // assuming the response is in JSON format
      //console.log(data.items)
      setInv(data.items);
    };

    fetchData();
  }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

  // rest of your component
  return (
    <div>
      <h4>Handcash Items</h4>
      <div className="inventory-grid">
        {inv.length > 0 ? ( // Check if inv has items before rendering the grid
          inv.map((item) => (
            <div key={item.id} className="inventory-item">
              
              <div>{item.name}</div>
              <img src={item.imageUrl} alt={item.name} />
            </div>
          ))
        ) : (
          <p>Loading inventory...</p>
        )}
      </div>
    </div>
  );
}

function formatCount(count){
  // format the data to a react component

  /*
  
bots "3"
(string)
buildVersion "v0.1.15.zip"
(string)
config "{ "name": "Shroom World - Deathmatch - FREE", "maxPlayers": 8, "type": 0, "bots": 3, "ip": "35.192.95.109:7784" }"
(string)
createdTime 1727266049037
(number)
instance_id "3860fc40-067e-4f0b-9e59-76aad75d2874"
(string)
ip "35.192.95.109:7784"
(string)
lastRestartTime 1727266049037
(number)
lastUpdated November 7, 2024 at 8:29:33 AM UTC-6
(timestamp)
mapName "/Game/Maps/psychmap"
(string)
match_id "9BjMDCb5i3Eihd6RfmWL"
(string)
maxPlayers "8"
(string)
name "DayShroom - Deathmatch - FREE"
(string)
onlineStatus false
(boolean)
players 0
(number)
port "7784"
(string)
serverConfigPath ""
(string)
serverId "35.192.95.109"
(string)
type 0 */
  const mappeddata = count.map(item => {
    return <div key={item.id}>{item.name}: {item.players}</div>
  });

  return mappeddata;
}

async function Instances(){
  const data = await $.ajax({
    type: "GET",
    url: "/server_list",
    success: function(data) {
      // data as list of objects
      console.log(data.servers);
      return data;
    }
  });
  return data.servers;
}


function OnlineCount(){
  const [onlineCount, setOnlineCount] = useState([]);

  useEffect(async () => {
    const fetchData = async () => {
      const data = await Instances();
      setOnlineCount(data);
    };
    fetchData();
  }, []);

  return (
    formatCount(onlineCount)
  );
}


function createGrid(size) {
  const gridContainer = document.getElementById('grid-container');
  
  // Clear existing grid if any
  gridContainer.innerHTML = '';

  // Create the grid
  for (let i = 1; i <= size; i++) {
      const square = document.createElement('div');
      square.classList.add('square');
      square.setAttribute('id', 'square_' + i);
      square.setAttribute('data-index', i); // Add data-index attribute
      square.setAttribute('title', 'Index: ' + i); // Add tooltip
      //square.addEventListener('mouseover', handleMouseOver); // Add mouseover event listener

      // Add text element with the index
      const text = document.createElement('span');
      text.textContent = i;
      square.appendChild(text);

      gridContainer.appendChild(square);
  }
}

// Function to handle mouseover event
function handleMouseOver(event) {
  const index = event.target.getAttribute('data-index');
  console.log('Index:', index);
  // You can do whatever you want with the index here.
}

// Function to activate squares based on indices
function activateSquares(indices) {
  for(const index of indices) {
      const square = document.getElementById('square_' + index);
      if (square) {
          square.classList.add('active');
      }
  }
}
function MintedCount() {
  const [minted, setMinted] = useState(null);
  const gridSize = 3000;
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/minted");
      const data = await response.json(); // assuming the response is in JSON format
      setMinted(data.minted.length);
      createGrid(gridSize);
      activateSquares(data.minted)
    };

    fetchData();
  }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

  // rest of your component
  return (
    <div>{minted}</div>
  );
}

async function getBalances(address){
  let selected = [];
  fetch('https://staging-backend.relayx.com/api/user/balance2/' + address)
    .then(response => response.json())
    .then(data1 => {
      console.log(data1);
      let collectibles = data1.data['collectibles'];
      let balances = data1.data['balances'];
      console.log({balances});
      selected = collectibles.filter(c => c.origin === '1ba1080086ca6624851e1fbff18d903047f2b75d3a9ffe5cc8bf49ed0fdb36a0_o2');
      
      // Remove unused variables
      // let slavettes = collectibles.filter(c => c.origin === '84769a5ac4cec62270093648c9978016640aeccb62c11b717313d41a4c74640c_o2');
      // let companions = collectibles.filter(c => c.origin === '880b9e67a2303c08845caa3a11804a0674b293d8d665b9f45938cea3eb216883_o2');
      // let eggs = collectibles.filter(c => c.origin === 'ae25cb3651f159df90941110f02c4cf09072b8cce2ad12e7bc1555fedd76b489_o2');
      // let metagops = collectibles.filter(c => c.origin === 'd0f9aa922a05ce5a4c4cf57342774826eefd62f82473062f16b65de1a2cfc3d9_o2');
      // let munitions = collectibles.filter(c => c.origin === 'ec5ed161a628a9985c41fa5d90a881cc547ab071c45a038c88b326669378cf54_o2');
      
      console.log(selected);

      $("#append").before("<h3>Selected Gopniks:</h3>");

      selected.forEach((element) => {
        console.log(element);
        $("#append").after(
          `<img width="100" height="100" src="https://berry.relayx.com/${element.berry.txid}">`
          )
      });

    })
    .catch(error => {
      console.error('Error:', error);
    });
      /*selected = selected.concat(slavettes)
      selected = selected.concat(companions)
      selected = selected.concat(eggs)
      selected = selected.concat(metagops)
      selected = selected.concat(munitions)
*/
      
      
      
    }



function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function removeButtonsIfTokensPresent() {
  const token = getCookie('token');
  const pandaToken = getCookie('panda_token');
  const hcToken = getCookie('hc_token');

  if (token && pandaToken && hcToken) {
      const relayButton = document.getElementById('relaybutton');
      const ywButton = document.getElementById('ywbutton');
      const hcButton = document.getElementById('hcbutton');

  }
}
async function getaddress(){

  try{
    const relayone = window.relayone
    const token = await relayone.authBeta();
  
  const [payload, signature] = token.split(".");
  const data = JSON.parse(atob(payload)); 
  
  function success(data)
  {
    alert("logged into relayx")
    window.location.reload()
  
  }
  
  console.log(data)
  
  try{
  $.ajax({
        type: "POST",
        url: "/auth",
        data: {paymail: data.paymail, origin: data.origin, pubkey: data.pubkey, signature: signature, payload: payload},
        success: success
      });
  }catch(error){
    console.log(error)
    
  }
  
  let paymail = data.paymail
  
  var user_address = null
  var walletJSON = null
  console.log(paymail)
  if (!paymail.includes("@relayx.io")){getBalances(paymail);return}
  $.get('https://api.relayx.io/v1/paymail/run/' + paymail, function (data2, status){
    console.log(data2)
    getBalances(data2.data)	
  
  
    
    
  })
  }catch(error){
  
    console.log(error)
    
  }
  
  
  }

  async function sign(){
    await init()

    
    


    const seed = Bip39.fromString(
      document.getElementById("seed").value
    ).toSeed();
    let bip32 = Bip32.fromSeed(seed)
    bip32 = bip32.derive("m/44'/236'/0'/2/0")
    const address = Address.fromPubKey(bip32.pubKey)
    console.log(address.toString())

    //const privkey = xpriv.derive_from_path("m/44'/236'/0'").to_string()
    console.log(bip32.pubKey)
    //const address = publicKey.to_string()
    console.log(address)
    //const address = P2PKHAddress.fromPublicKey(publicKey);
    const message = "gopnik transfer";
    console.log(message, Buffer.from(message).buffer, new Buffer.from(message), bip32)
    const signature = Bsm.sign(new Buffer.from(message), bip32)

    
console.log(signature)
    const data = {
      paymail: "relayxSig",
      origin: "relayxSig",
      pubkey:  bip32.pubKey.toString(),
      signature: signature.toString(),
      payload: message,
      address: address.toString()
    };
    fetch("/auth", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(data)
    }).then((data)=>{console.log(data); window.location.reload()})
      .catch(error => {
        console.error('Error:', error);
      });
  
  }

  
function hcclick(){

  // try to get ?login_code= from url
  const urlParams = new URLSearchParams(window.location.search);
  const login_code = urlParams.get('login_code');
  // if window location is localhost:
   if (window.location.href.includes("localhost")){
    window.location.href="https://app.handcash.io/#/authorizeApp?appId=663450f13bc92f2bff7065af"
    return
   }


  if(login_code){window.location.href="https://app.handcash.io/#/authorizeApp?appId=65de4bfb31087fc4de1625d0&login_code=" + login_code;return}
  
  window.location.href="https://app.handcash.io/#/authorizeApp?appId=65de4bfb31087fc4de1625d0"
  return
}

const token = getCookie('token');
const pandaToken = getCookie('panda_token');
const hcToken = getCookie('hc_token');

const urlParams = new URLSearchParams(window.location.search);
const login_code = urlParams.get('login_code');
const done_code = urlParams.get('done');
if (login_code && done_code != "true" && !hcToken){
  alert("Please login to HandCash to continue.")
}

if (done_code && hcToken){
  alert("You have been logged in to HandCash. You may return to Goplandia.")
}

if (login_code && done_code != "true" && hcToken){
  alert("You have been logged in to HandCash. You may return to Goplandia.")

}
function App() {

  const [activeTab, setActiveTab] = useState('signin'); // Define state variables

  return (
<PandaProvider>
 
  <div id="background-loading">
    <div class="wrapper">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <span>Loading</span>
    </div>
  </div>
  <br></br>
  <br></br>
  <br></br>
  <h1 class="text-center neonText "><b>Goplandia Secret Underground Website</b></h1>
  <br></br>
 <div><h4 class="text-center ">In-Game Players: </h4>
  <p class="text-center "><OnlineCount /></p>
  </div>
<div class="text-center"><Chat /></div>
  <br></br>
  <div class="text-center">
  {/*<Button onClick={orderTickets}>Order 1(ONE) Bober Ticket</Button>*/}
  </div>
  <div class="container text-center border-white rounded-4 border border-2 p-4">

    <ul class="nav nav-tabs">
    <li class="nav-item">
        <a class={`nav-link ${activeTab === 'signin' ? 'active' : ''}`} onClick={() => setActiveTab('signin')}>Sign-In</a>
      </li>
      <li class="nav-item">
        <a class={`nav-link ${activeTab === 'claim' ? 'active' : ''}`} onClick={() => setActiveTab('claim')}>Claim Gopniks</a>
      </li>
      <li class="nav-item">
        <a class={`nav-link ${activeTab === 'leaderboard' ? 'active' : ''}`} onClick={() => setActiveTab('leaderboard')}>Leaderboard</a>
      </li>

    </ul>
    


    {activeTab === 'claim' && (
      <div class="col-12 p-3">
        <h3 class="text-center neonText "><b>Claim your Gopniks from RelayX!</b></h3>
        <br></br>
        
        <br></br>

        <Snapshot></Snapshot>
        <br></br>
        <RunInvCount />
        <br></br>
        <HCInvCount />
        <br></br>
        <h4 class="neonText ">Total Gopniks Bridged from RelayX to Handcash:</h4>
        <h4 class="text-center"><MintedCount /> out of <br></br>3000 <br></br></h4><br></br>
        <p class="text-center">🟧The grid map will color the numbers which have been claimed in orange.</p>
        <div id="grid-container"></div>
      </div>
    )}

    {activeTab === 'leaderboard' && (
      <div class="col-12 p-3">
        <Leaderboard></Leaderboard>
      </div>
    )}

    {activeTab === 'signin' && (
      <div class="col-12 p-3">
        <h4>Please make sure you are logged into HandCash and a RelayX wallet before you attempt to claim your items. Use Yours.org wallet to sign or provide RelayX seed phrase. The seed phrase is never sent over the network.</h4>
        
        <div id="appender"></div>
        
        <HCButton></HCButton>
        <br></br><ConnectPanda></ConnectPanda><br></br>
        <form onSubmit={e => { e.preventDefault(); sign() }}>
          <p>RelayX Sign-In</p>
          <input type="password" id="seed" placeholder="enter 12-word relayx seed phrase separated by spaces" class="form-control my-2"></input>
          <Button onClick={sign} type="button" class="btn btn-primary">Sign Auth</Button>
        </form>
      </div>
    )}
  </div>

  <div class="container text-center border-white border border-2 rounded-4 p-4 mt-4">
    <p>Try the <a href="https://itch.io/app">itch.io/app</a></p>
    <iframe frameborder="0" src="https://itch.io/embed/1506380?border_width=5&amp;bg_color=191919&amp;fg_color=ffffff&amp;link_color=c28cf3&amp;border_color=96a1b1" width="560" height="175"><a href="https://gopnikz.itch.io/goplandia">goplandia by Gopnikz</a></iframe>
    <br></br><a href="/privacy_policy">Privacy Policy</a> | <a href="/terms_and_conditions">Terms And Conditions</a> | Copyright 2024 Gopnikz
  </div>
</PandaProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SocketProvider>
    <App />
    </SocketProvider>
  </React.StrictMode>
);